import Button from 'components/dumb/Button'
import Block from 'components/dumb/Block'
import ProjectPageTier from 'components/dumb/ProjectPageTier'
import { useNavigate } from 'react-router-dom'
import { $staking } from 'models/staking'
import { useStore } from 'effector-react'
import NotConnectedText from 'resources/images/tier/ido/notConnectedText.svg'
import t from 'utils/t'
import { openWallet } from 'models/wallet'
import Timer from 'components/dumb/Timer'

interface AllocationAndWaveProps {
  name: string
  level: number
  maxAllocation: string
  showTimer: boolean
  waveText: string
  nftImageUrl?: string
  symbol?: string
  waveStartTime?: string | null
}

interface StakeMoreProps {
  name: string
  level: number
  nftImageUrl?: string
  moreTokens?: string
}

interface HighestTierProps {
  name: string
  nftImageUrl?: string
}

export type Props =
  | {
      state: 'notConnected'
    }
  | {
      state: 'noTier'
    }
  | {
      state: 'missedIDO'
    }
  | {
      state: 'IDOfailed'
    }
  | {
      state: 'noTierRequirements'
    }
  | ({
      state: 'stakeMore'
    } & StakeMoreProps)
  | ({
      state: 'allocationAndWave'
    } & AllocationAndWaveProps)
  | ({
      state: 'highestTier'
    } & HighestTierProps)

export default function ProjectPageTierMaster(props: Props) {
  switch (props.state) {
    case 'notConnected':
      return <NotConnected />
    case 'noTier':
      return <NoTier />
    case 'missedIDO':
      return <MissedIDO />
    case 'IDOfailed':
      return <IDOFailed />
    case 'noTierRequirements':
      return <NoTierRequirements />
    case 'stakeMore':
      return <StakeMore {...props} />
    case 'allocationAndWave':
      return <AllocationAndWave {...props} />
    case 'highestTier':
      return <HighestTier {...props} />
    default:
      return <NA />
  }
}

function StakeMore(props: StakeMoreProps) {
  const { stakingSymbol } = useStore($staking)
  const navigate = useNavigate()
  return (
    <ProjectPageTier
      containerClassName="min-h-[300px]"
      title={props.name}
      nftImageUrl={props.nftImageUrl}
      description={
        <div className={`${props.level !== 2 ? 'max-w-[60%]' : ''}`}>
          {props.level! > 5 &&
            t('tier_update_description', {
              tokenAndSymbol: (
                <span className="text-primary">
                  {props.moreTokens} {stakingSymbol}
                </span>
              ),
            })}
          {props.level! <= 5 && props.level! >= 3 && (
            <div>
              Stake{' '}
              <span className="text-primary">
                {props.moreTokens} {stakingSymbol}
              </span>{' '}
              to increase your allocation.
            </div>
          )}
          {props.level! < 3 && t('highest_tier_text')}
        </div>
      }
      button={
        <Button type="gradientBorder" onClick={() => navigate('/staking')}>
          {t('go_to_staking')}
        </Button>
      }
    />
  )
}

function NotConnected() {
  return (
    <Block
      bgImage="bg-idoNotConnected bg-right-bottom"
      containerClassName="flex h-full flex-col min-h-[300px]"
      wrapperClassName="ml:col-span-1 col-span-2"
    >
      <img
        src={NotConnectedText}
        className="absolute top-10 left-6 w-[30%] t:w-[24%] dxs:w-[40%]"
        alt=""
      />
      <div className="mt-auto ml-auto flex flex-col items-center">
        <Button onClick={() => openWallet()} type="gradientBorder">
          {t('connect_wallet')}
        </Button>
        <span className="mt-2 inline-flex text-sm font-medium text-brightGray">
          {t('connect_wallet_to_see_your_tier')}
        </span>
      </div>
    </Block>
  )
}

function NoTier() {
  const navigate = useNavigate()

  return (
    <ProjectPageTier
      containerClassName="mx-auto min-h-[300px]"
      title={t('get_your_tier')}
      description={
        <div>
          Looks like you don't have a tier.
          <br /> To participate in Private IDOs, you need Mont Blanc at least.
        </div>
      }
      titleClassName="text-center"
      descriptionClassName="text-center !text-[1.1rem]"
      bgImage="bg-idoNoTier"
      button={
        <div className="flex justify-center">
          <Button onClick={() => navigate('/staking')} type="gradientBorder">
            {t('go_to_staking')}
          </Button>
        </div>
      }
    />
  )
}

function MissedIDO() {
  const navigate = useNavigate()

  return (
    <ProjectPageTier
      containerClassName="mx-auto min-h-[300px]"
      title="Missed IDO"
      description={t('missed_ido')}
      descriptionClassName="text-center"
      titleClassName="text-center"
      bgImage="bg-idoNoTier"
      button={
        <div className="flex justify-center">
          <Button onClick={() => navigate('/staking')} type="gradientBorder">
            {t('go_to_staking')}
          </Button>
        </div>
      }
    />
  )
}

function IDOFailed() {
  return (
    <ProjectPageTier
      containerClassName="mx-auto min-h-[300px]"
      title="IDO is Canceled"
      description={t('ido_canceled')}
      descriptionClassName="text-center"
      titleClassName="text-center"
      bgImage="bg-idoNoTier grayscale"
    />
  )
}

function NoTierRequirements() {
  const navigate = useNavigate()
  const { stakingSymbol } = useStore($staking)

  return (
    <ProjectPageTier
      containerClassName="min-h-[300px]"
      title={t('no_tier_requirements')}
      description={
        <div className="text-center">
          {t('no_tier_requirements_text', { symbol: stakingSymbol })}
        </div>
      }
      titleClassName="text-center"
      bgImage="bg-idoNoTierRequirements"
      button={
        <div className="mt-4 flex justify-center mm:mt-16">
          <Button type="gradientBorder" onClick={() => navigate('/staking')}>
            {t('go_to_staking')}
          </Button>
        </div>
      }
    />
  )
}

function HighestTier(props: HighestTierProps) {
  return (
    <ProjectPageTier
      containerClassName="min-h-[300px]"
      title={props.name}
      description={t('highest_tier_text')}
      nftImageUrl={props.nftImageUrl}
    />
  )
}

function AllocationAndWave({
  maxAllocation,
  nftImageUrl,
  name,
  symbol,
  waveStartTime,
  waveText,
  showTimer,
}: AllocationAndWaveProps) {
  return (
    <ProjectPageTier
      containerClassName="min-h-[300px]"
      title={name}
      innerClassName="justify-between"
      description={
        <div className="flex flex-col">
          <div className="text-2xl font-light leading-[130%] text-brightGray">
            Maximum allocation
          </div>
          <div className="mb-[6px] flex items-baseline space-x-2 text-primary">
            <span className="text-[39px] leading-[150%]">{maxAllocation}</span>
            <span className=" text-2xl">{symbol}</span>
          </div>
          <div className="text-2xl font-light leading-[130%] text-brightGray">
            {waveStartTime ? 'Your wave in' : 'Your wave'}
          </div>
          <div className="flex items-baseline space-x-2 text-primary">
            {showTimer ? (
              <Timer
                endTime={waveStartTime}
                countdownClassName="text-[39px] font-neue mt-2"
                containerClassName="flex-col !items-start"
              />
            ) : (
              <div className="text-[39px] leading-[150%]">{waveText}</div>
            )}
          </div>
        </div>
      }
      nftImageUrl={nftImageUrl}
    />
  )
}
function NA() {
  return <ProjectPageTier containerClassName="min-h-[300px]" title={'N/A'} />
}
