import isEmpty from 'lodash/isEmpty'
import { ReactComponent as UnlockedIcon } from 'resources/images/icons/unlocked.svg'
import { ReactComponent as LockedIcon } from 'resources/images/icons/locked.svg'
import { useCallback, useState } from 'react'
import Button from './Button'
import t from 'utils/t'
import Block from './Block'
import ChevronButton from './ChevronButton'
import classNames from 'classnames'
import { CheckpointType } from 'models/pools/pools'

export type Props = {
  showClaimButton: boolean
  claimButtonDisabled: boolean
  idoTokenSymbol: string
  viaAirdrop: boolean
  checkpoints?: CheckpointType[]
}

export default function ProjectPageVesting({
  checkpoints,
  terminalRef,
  showClaimButton,
  claimButtonDisabled,
  idoTokenSymbol,
  viaAirdrop,
}: Props & { terminalRef: any }) {
  const [showContent, setShowContent] = useState(true)
  const handleScrollToTerminalButtonClick = useCallback(() => {
    if (terminalRef && terminalRef.current) {
      terminalRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [terminalRef])

  if (isEmpty(checkpoints) || viaAirdrop) {
    return null
  }

  const checkpointsCount = checkpoints?.length

  return (
    <Block wrapperClassName="mb-12">
      <div
        className="group flex cursor-pointer items-center justify-between text-2xl font-light text-brightGray"
        onClick={() => setShowContent(!showContent)}
      >
        <div>{t('vesting_schedule')}</div>
        <div className="flex items-center space-x-[30px]">
          <ChevronButton
            open={showContent}
            onClick={() => setShowContent(!showContent)}
          />
        </div>
      </div>

      <div>
        <div>
          {showContent && (
            <div className="mt-6 mb-2 flex flex-col space-y-3">
              {checkpoints?.map(
                (
                  {
                    status,
                    yourUnlockComputed,
                    totalAmountComputed,
                    unlockDate,
                    unlockPercent,
                  },
                  i
                ) => {
                  const claimed = isClaimed(status)
                  const locked = isLocked(status)
                  const unlocked = isUnlocked(status)

                  return (
                    <div
                      key={`vesting-checkpoint-${i}`}
                      className={classNames(
                        `
                          relative grid items-center justify-between rounded-lg py-3 px-4
                          ml:grid-cols-[1fr,1fr,1fr,1fr,1fr,0.7rem]
                          dxs:grid-cols-[4rem,14rem,1fr,1fr,1fr,1fr,0.7rem]
                        `,
                        {
                          'bg-doveGray/5': claimed,
                          'vesting-gradient blockShadow border border-white':
                            unlocked,
                          'border border-black/5': locked,
                        }
                      )}
                    >
                      <div className="mb-[0.6rem] text-sm ml:mb-0 ml:hidden dxs:block">
                        <span
                          className={classNames({
                            'text-brightGray': unlocked || locked,
                            'text-friarGray/50': claimed,
                            'font-medium': !claimed,
                          })}
                        >
                          {i + 1}
                        </span>
                        <span
                          className={classNames({
                            'text-friarGray': unlocked || locked,
                            'text-friarGray/50': claimed,
                          })}
                        >
                          /{checkpointsCount}
                        </span>
                      </div>
                      <Field
                        title={t('date')}
                        value={unlockDate}
                        status={status}
                      />
                      <Field
                        title={t('percentage')}
                        value={unlockPercent}
                        status={status}
                      />
                      <Field
                        title={t('total_unlock')}
                        value={totalAmountComputed}
                        status={status}
                      />
                      <Field
                        title={t('your_unlock')}
                        value={yourUnlockComputed}
                        status={status}
                      />
                      <Field
                        title={t('status')}
                        value={status}
                        status={status}
                        className={unlocked ? 'text-malachite' : ''}
                      />
                      {claimed || unlocked ? (
                        <UnlockedIcon
                          className={classNames(
                            'absolute top-[1rem] right-[1rem] h-[0.8rem] w-[0.7rem] ml:static',
                            {
                              'fill-friarGray': claimed,
                              'fill-malachite': unlocked,
                            }
                          )}
                        />
                      ) : (
                        <LockedIcon className="absolute top-[0.6rem] right-[0.6rem] h-[0.8rem] w-[0.7rem] fill-brightGray ml:static" />
                      )}
                    </div>
                  )
                }
              )}

              {showClaimButton && (
                <div className="mt-4 mb-2 flex items-center justify-end">
                  <Button
                    disabled={claimButtonDisabled}
                    onClick={handleScrollToTerminalButtonClick}
                  >
                    {`${t('vesting_claim_button_text')} ${idoTokenSymbol}`}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Block>
  )
}

type FieldProps = {
  title: string
  value: string
  status: string
  className?: string
}

function Field({ title, value, status, className }: FieldProps) {
  const claimed = isClaimed(status)
  const locked = isLocked(status)
  const unlocked = isUnlocked(status)

  return (
    <div className="mb-[0.6rem] flex text-sm ml:mb-0 ml:flex-col">
      <div
        className={classNames('w-[5rem] flex-shrink-0', {
          'text-doveGray/50': claimed,
          'text-friarGray': locked || unlocked,
        })}
      >
        {title}
      </div>
      <div
        className={classNames(className, {
          'text-doveGray/50': claimed,
          'font-medium text-brightGray': locked || unlocked,
        })}
      >
        {value}
      </div>
    </div>
  )
}

const isClaimed = (status: string) => status === 'Claimed'
const isLocked = (status: string) => status === 'Locked'
const isUnlocked = (status: string) => status === 'Unlocked'
