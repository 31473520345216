import { attach, createEffect, createEvent, createStore } from 'effector'
import { ethers } from 'ethers'
import { PoolQuery } from 'gql'
import { graphqlSdk } from 'gql/client'
import { $session } from 'models/sessions'
import { $address, $provider } from 'models/wallet'
import invariant from 'tiny-invariant'
import { toDecimal } from 'utils/numbers'
import { poolAbi } from './abi'
import { Pool } from './pools'
import poolMock from './pool.json'
/*
import mockJSON from './mock.json'
import { PoolsWithSessionQuery } from 'gql'
*/

export const fetchPools = createEvent()
export const fetchPool = createEvent<{ id: string }>()
export const setSelectedPoolId = createEvent<string>()
export const addParticipantAirdropTarget = createEvent<{
  poolId: string
  address: string
}>()

export const $poolNetworkIsOk = createStore(true)
export const $pools = createStore<Pool[]>([])
export const $selectedPool = createStore<Pool | null>(null)

export const $activePools = $pools.map((pools) =>
  pools
    .filter((pool) => !pool.finished && !pool.comingSoon)
    .sort((a, b) => (a.position > b.position ? 1 : -1))
)

export const $upcomingPools = $pools.map((pools) =>
  pools
    .filter((pool) => pool.comingSoon)
    .sort((a, b) => (a.position > b.position ? 1 : -1))
)

export const $finishedPools = $pools.map((pools) =>
  pools
    .filter((pool) => pool.finished)
    .sort((a, b) => (a.endTimeTimestamp < b.endTimeTimestamp ? 1 : -1))
)

export const fetchPoolsWithSessionFx = attach({
  source: $session,
  async effect(session) {
    /*return mockJSON.data as PoolsWithSessionQuery*/
    return await graphqlSdk.PoolsWithSession({
      session,
    })
  },
})

export const fetchPoolsFx = createEffect(async () => {
  return await graphqlSdk.Pools()
})

export const fetchPoolFx = attach({
  source: $session,
  async effect(session, { id }) {
    // return poolMock.data as PoolQuery
    return graphqlSdk.Pool({ id, session })
  },
})

export const buyFx = attach({
  source: [$address, $provider],
  async effect(
    [address, provider],
    { pool, rawAmount }: { pool: Pool; rawAmount: string }
  ) {
    invariant(provider, 'web3 provider not found')

    // await window.ethereum?.request({ method: 'eth_requestAccounts' })

    const mlt = toDecimal(10).pow(pool.targetTokenDecimals)
    const amount = toDecimal(rawAmount).mul(mlt).floor().toString()

    const signer = provider.getSigner()
    const res = await signer.sendTransaction({
      from: address,
      to: pool.address,
      data: '0xcd3293de', // reserve
      value: amount,
    })

    return `${res?.hash ?? ''}`
  },
})

export const claimFx = attach({
  source: $provider,
  async effect(provider, pool: Pool) {
    invariant(provider, 'web3 provider not found')

    const signer = provider.getSigner()
    const poolContract = new ethers.Contract(pool.address, poolAbi, signer)
    const res = await poolContract.withdrawReservedTokens()
    return `${res?.hash ?? ''}`
  },
})

export const refundFx = attach({
  source: $provider,
  async effect(provider, pool: Pool) {
    invariant(provider, 'web3 provider not found')

    const signer = provider.getSigner()
    const poolContract = new ethers.Contract(pool.address, poolAbi, signer)
    const res = await poolContract.refund()
    return `${res?.hash ?? ''}`
  },
})

export type RegisterModalStatus =
  | 'open'
  | 'pending'
  | 'failed'
  | 'registered'
  | ''

export const setRegisterModalStatus = createEvent<RegisterModalStatus>()
export const $registerModalStatus = createStore<RegisterModalStatus>('')

export const registerFx = createEffect(async (pool: Pool) => {
  const session = $session.getState()

  const res = await graphqlSdk.RegisterPoolParticipation({
    input: {
      poolAddress: pool.address,
      blockchain: pool.blockchain,
      session,
    },
  })

  return res
})

export const addTokenFx = attach({
  source: $provider,
  async effect(
    provider,
    {
      tokenAddress,
      tokenSymbol,
      tokenDecimals,
    }: {
      tokenAddress: string
      tokenSymbol: string
      tokenDecimals: number
    }
  ) {
    invariant(provider, 'web3 provider not found')
    try {
      if (provider.provider.request) {
        await provider.provider?.request({
          method: 'wallet_watchAsset',
          params: {
            //@ts-ignore TODO: how to fix?
            type: 'ERC20',
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: '',
            },
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  },
})

export const withdrawFx = attach({
  source: [$address, $provider],
  async effect([address, provider], { pool }: { pool: Pool }) {
    invariant(provider, 'web3 provider not found')

    const signer = provider.getSigner()
    const res = await signer.sendTransaction({
      from: address,
      to: pool.address,
      data: '0xe07fa3c1', // withdraw
      // value: amount,
    })

    return `${res?.hash ?? ''}`
  },
})

export const addParticipantAirdropTargetFx = attach({
  source: $session,
  async effect(
    session,
    { poolId, address }: { poolId: string; address: string }
  ) {
    return graphqlSdk.AddParticipantAidrdopTarget({
      input: {
        session,
        target: address,
        poolId,
      },
    })
  },
})
