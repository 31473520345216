import { combine, sample, split } from 'effector'
import { $selectedChainId } from 'models/wallet'
import { $session } from 'models/sessions'
import {
  $poolNetworkIsOk,
  $pools,
  $selectedPool,
  fetchPoolFx,
  fetchPools,
  fetchPoolsFx,
  fetchPoolsWithSessionFx,
  fetchPool,
  addParticipantAirdropTargetFx,
  addParticipantAirdropTarget,
} from './'

import { getChainId } from 'utils/blockchain'
import {
  $registerModalStatus,
  registerFx,
  RegisterModalStatus,
  setRegisterModalStatus,
} from '.'
import { PrivatePool, PublicPool } from './pools'

// запрашиваем данные
split({
  clock: fetchPools,
  source: $session,
  match: {
    a1: (session) => session.length === 0,
    a2: (session) => session.length > 0,
  },
  cases: {
    a1: fetchPoolsFx,
    a2: fetchPoolsWithSessionFx,
  },
})

sample({
  clock: fetchPool,
  filter: ({ id }) => Boolean(id),
  target: fetchPoolFx,
})

fetchPoolFx.fail.watch(() => {
  window.location.href = '/'
})

sample({
  clock: fetchPoolFx.doneData,
  fn: (data) => {
    if (data.pool) {
      return data.pool?.meta.private
        ? new PrivatePool(data.pool)
        : new PublicPool(data.pool)
    }
    return null
  },
  target: $selectedPool,
})
// Если данные пришли, сохраняем
sample({
  clock: [fetchPoolsWithSessionFx.doneData, fetchPoolsFx.doneData],
  fn: (data) => {
    const pools = (
      data.pools.sort((p1, p2) => (p1.id > p2.id ? 1 : -1)) ?? []
    ).map((p) => (p.meta.private ? new PrivatePool(p) : new PublicPool(p)))
    return pools
  },
  target: $pools,
})

// ------------------------------------
sample({
  clock: setRegisterModalStatus,
  fn: (status: RegisterModalStatus): RegisterModalStatus => status,
  target: $registerModalStatus,
})

sample({
  clock: registerFx,
  fn: (): RegisterModalStatus => 'pending',
  target: $registerModalStatus,
})

sample({
  clock: registerFx.doneData,
  fn: ({ registerPoolParticipation: { success } }): RegisterModalStatus =>
    success ? 'registered' : 'failed',
  target: $registerModalStatus,
})

sample({
  clock: registerFx.failData,
  fn: (): RegisterModalStatus => 'failed',
  target: $registerModalStatus,
})

sample({
  clock: combine($selectedPool, $selectedChainId),
  fn: ([selectedPool, chainId]) => {
    if (selectedPool) {
      return getChainId(selectedPool.blockchain) === chainId
    }

    return false
  },
  target: $poolNetworkIsOk,
})

sample({
  clock: addParticipantAirdropTarget,
  target: addParticipantAirdropTargetFx,
})
