import classNames from 'classnames'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { ReactComponent as HeaderLogo } from 'resources/images/header/razer-logo.svg'
import { ReactComponent as PlanetIcon } from 'resources/images/icons/planet.svg'
import { ReactComponent as StakingIcon } from 'resources/images/icons/staking.svg'
import { ReactComponent as DashboardIcon } from 'resources/images/icons/dashboard.svg'
import { ReactComponent as LtMiningIcon } from 'resources/images/icons/lt.svg'
import { ReactComponent as BurgerIcon } from 'resources/images/icons/burger.svg'
import { ReactComponent as CrossIcon } from 'resources/images/icons/cross.svg'
import { ReactComponent as MenuLinkV } from 'resources/images/header/menu-link-v.svg'
import { ReactComponent as MenuLinkH } from 'resources/images/header/menu-link-h.svg'
import { ReactComponent as MenuLinkM } from 'resources/images/header/menu-link-m.svg'

import React, { useState } from 'react'
import SocialLink from 'components/dumb/SocialLink'
import { SOCIAL_LINKS_HEADER } from 'utils/links'
import SmartWalletButton from 'components/smart/SmartWalletButton/ButtonWithTiers'

export default function SmartHeader() {
  const [socOpen, setSocOpen] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const headerNavLinks = [
    {
      to: '/',
      label: 'IDOs',
      icon: <PlanetIcon />,
    },
    {
      to: '/staking',
      label: 'STAKING',

      icon: <StakingIcon />,
    },
    {
      to: '/chakra',
      label: 'CHAKRA DASHBOARD',

      icon: <DashboardIcon />,
    },
    {
      to: `/liquidity-mining/${process.env.REACT_APP_LM_ADDRESS}`,
      label: `LIQUIDITY MINING`,
      icon: <LtMiningIcon />,
    },
  ].map(({ to, label, icon }, i) => {
    const clonedIcon = React.cloneElement(icon, {
      key: i,
      className:
        'h-[30px] w-[30px] group-hover:opacity-100 t:h-[40px] t:w-[40px] ds:h-[30px] ds:w-[30px] dl:h-[40px] dl:w-[40px] relative z-3 opacity-40',
    })

    return (
      <NavLink
        key={to}
        to={to}
        className="h-[50px] ml:h-[75px] t:h-[80px] ds:h-full"
      >
        {({ isActive: _isActive }) => {
          const isPool = pathname.includes('pool') && to === '/'
          const isLM =
            pathname.includes('liquidity-mining') &&
            to.includes('liquidity-mining')
          const isActive = _isActive || isPool || isLM
          return (
            <div className="group relative flex h-full flex-col items-center py-2">
              {isActive ? (
                <>
                  <div
                    className={classNames(
                      'z-2 link-boxShadow absolute top-0 h-full w-full bg-cover bg-no-repeat',
                      'rounded-lg'
                    )}
                  />
                  <MenuLinkM className="absolute top-0 block h-full w-full ml:hidden" />
                  <MenuLinkV className="absolute top-0 hidden h-full w-full ds:block" />
                  <MenuLinkH className="absolute top-0 hidden h-full w-full ml:block ds:hidden" />
                </>
              ) : (
                <div className="z-2 absolute top-0 h-full w-full rounded-lg border border-geyser/40 ds:border-none" />
              )}
              <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center">
                {isActive
                  ? React.cloneElement(clonedIcon, {
                      style: {
                        opacity: 1,
                      },
                    })
                  : clonedIcon}
                <span
                  className={classNames(
                    'relative z-30 mt-1 text-[10px] leading-3 antialiased t:text-[12px] ds:text-[10px] dl:text-[12px]',
                    'hidden ml:block',
                    'text-center',
                    {
                      '!text-primary': isActive,
                      'text-brightGray/40': !isActive,
                    }
                  )}
                >
                  {label}
                </span>
              </div>
            </div>
          )
        }}
      </NavLink>
    )
  })

  const handleBurgerClick = () => {
    setSocOpen((s) => !s)
  }

  const iconClassName =
    'fill-current hover:text-geyser h-[24px] w-[24px] ds:h-[18px] ds:w-[18px] dl:h-[24px] dl:w-[24px]'
  const socLinks = [
    <SocialLink
      key="medium"
      icon="medium"
      className={iconClassName}
      url={SOCIAL_LINKS_HEADER.medium}
    />,
    <SocialLink
      key="twitter"
      icon="twitter"
      className={iconClassName}
      url={SOCIAL_LINKS_HEADER.twitter}
    />,
    <SocialLink
      key="discord"
      icon="discord"
      className={iconClassName}
      url={SOCIAL_LINKS_HEADER.discord}
    />,
    <SocialLink
      key="telegram"
      icon="telegram"
      className={iconClassName}
      url={SOCIAL_LINKS_HEADER.telegram}
    />,
  ]

  const soc = (
    <div className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 grid-cols-2 gap-2 text-mystic ml:grid ">
      {socLinks}
    </div>
  )

  return (
    <div
      className={classNames(
        'fixed bottom-0 z-30 grid',
        'grid-cols-auto ml:grid-cols-[106px,auto] t:grid-cols-[140px,auto] dxs:grid-cols-[140px,auto,140px] ',
        'ds:left w-full items-stretch',
        'justify-center gap-x-[12px] gap-y-0 ml:gap-x-[20px] t:gap-x-[30px] ',
        'bg-athensGray3 py-[15px] ',
        'ds:fixed ds:flex ds:h-full',
        'ds:w-[80px] ds:flex-col ds:justify-start ds:border-r ds:border-geyser/40 ds:py-0 dl:w-[110px] ',
        ''
      )}
    >
      <div
        onClick={() => navigate('/')}
        className={classNames(
          'relative flex cursor-pointer flex-col rounded-lg',
          'border border-geyser/40 bg-geyser/18 ds:mx-auto ds:my-12 ds:border-0 ds:bg-transparent',
          'flex flex-col items-center justify-center',
          'hidden ml:flex'
        )}
      >
        <HeaderLogo className="h-9 w-9 t:h-12 t:w-12 ds:h-8 ds:w-8 dl:h-12 dl:w-12" />
      </div>
      <div
        className={classNames(
          'headerLinks grid items-center gap-x-3 gap-y-0 divide-y-0 divide-geyser/40 ds:divide-y ',
          'grid-cols-[repeat(5,50px)] ml:grid-cols-[repeat(5,75px)] t:grid-cols-[repeat(5,100px)]  ds:grid-cols-1 ',
          'auto-rows-[50px] ml:auto-rows-[76px] dl:auto-rows-[105px]',
          {
            grid: !socOpen,
            'hidden ml:grid': socOpen,
          }
        )}
      >
        {headerNavLinks}
        <div className="relative block h-[50px] w-full ml:h-[75px] t:h-[80px] ds:hidden ">
          <SmartWalletButton />
        </div>
        <a
          className="soc relative hidden h-[50px] !border-b border-geyser/40 t:h-[80px] ds:block ds:h-full"
          onClick={(e) => e.preventDefault()}
          href="/#"
        >
          {soc}
        </a>
      </div>
      <div
        className={classNames(
          'grid auto-rows-[50px] grid-cols-[repeat(4,50px)] items-center gap-x-3',
          {
            'grid ml:hidden': socOpen,
            hidden: !socOpen,
          }
        )}
      >
        {socLinks.map((l, index) => (
          <div
            className="relative h-[50px] w-full rounded-lg !border border-geyser/40 "
            key={`soc-link-${index}`}
          >
            <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center text-primary">
              {l}
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={handleBurgerClick}
        className={classNames(
          'relative flex-col items-center justify-center rounded-lg border border-x border-geyser/40 bg-geyser/18',
          'hidden dxs:block ds:hidden',
          'cursor-pointer ml:cursor-default'
        )}
      >
        {soc}
        {!socOpen && (
          <BurgerIcon className="absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2 items-center ml:hidden" />
        )}
        {socOpen && (
          <CrossIcon className="absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2 items-center ml:hidden" />
        )}
      </div>
    </div>
  )
}
