import { ReactComponent as ChevronIcon } from 'resources/images/icons/chevron-left.svg'

interface Props {
  open: boolean
  onClick(): void
}

export default function ChevronButton({ onClick, open }: Props) {
  return (
    <div
      className="relative right-0 top-0 h-[40px] w-[40px] shrink-0 cursor-pointer rounded-[5px] bg-geyser/40"
      onClick={onClick}
    >
      <ChevronIcon
        className={`absolute h-[50%] w-[50%] fill-current text-primary ${
          open ? 'rotate-90' : '-rotate-90'
        } top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform`}
      />
    </div>
  )
}
