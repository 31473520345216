import classNames from 'classnames'
import ProjectPageTerminal from 'components/collections/ProjectPageTerminalMaster'
import ProjectPageTierMaster from 'components/collections/ProjectPageTierMaster'
import DetailsInformation from 'components/dumb/DetailsInformation'
import ProjectPageHeading from 'components/dumb/ProjectPageHeading'
import ProjectPageSocialLinks from 'components/dumb/ProjectPageSocialLinks'
import ProjectPageTimeline from 'components/dumb/ProjectPageTimeline'
import ProjectPageVesting from 'components/dumb/ProjectPageVesting'

//import SaleProgress from 'components/dumb/SaleProgress'
import SaleStatistics from 'components/dumb/SaleStatistics'
import Spinner from 'components/dumb/Spinner'

import ParticipationGuide from 'components/smart/SmartProjectPageParticipationGuide'
import { useStore } from 'effector-react'
import { $selectedPool, fetchPool } from 'models/pools'
import { $session } from 'models/sessions'
import { $staking } from 'models/staking'
import { $address, $targetTokenBalance } from 'models/wallet'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

export default function ProjectDetails() {
  const targetTokenBalance = useStore($targetTokenBalance)
  const connected = useStore($session.map(Boolean))
  const address = useStore($address)
  const staking = useStore($staking)
  const pool = useStore($selectedPool)
  const terminalRef = useRef(null)
  const { id } = useParams()

  useEffect(() => {
    if (!id) {
      return
    }
    fetchPool({ id })

    const tId = setInterval(() => {
      fetchPool({ id })
    }, 5000)

    return () => {
      clearInterval(tId)
    }
  }, [id])

  const base =
    'my-[3.35rem] grid dxs:grid-cols-2 gap-y-[1.4rem] dxs:gap-[1.4rem]'

  // setSelectedPoolId(selectedPoolId ?? '')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="relative min-h-[80vh]">
      {!pool ? (
        <Spinner className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2" />
      ) : (
        <>
          <ProjectPageHeading {...pool.getHeadingProps()} />
          <div className={classNames(base)}>
            <SaleStatistics {...pool.getSaleStatisticsProps()} />
            {!pool.finished || pool.failed ? (
              <ProjectPageTierMaster
                {...pool.getTierProps(connected, staking)}
              />
            ) : null}
            <div className="col-span-2 ml:col-span-1" ref={terminalRef}>
              <ProjectPageTerminal
                {...pool.getTerminalProps({
                  address,
                  connected,
                  targetTokenBalance,
                })}
              />
            </div>
          </div>
          <ProjectPageTimeline {...pool.getTimelineProps()} />
          <ParticipationGuide
            terminalRef={terminalRef}
            {...pool.getParticipationGuideProps(connected)}
          />
          <ProjectPageSocialLinks {...pool.getSocialLinks()} />
          {!pool.failed ? (
            <ProjectPageVesting
              terminalRef={terminalRef}
              {...pool.getVesting()}
            />
          ) : null}
          {pool.original.meta.markdown && (
            <DetailsInformation markdown={pool.original.meta.markdown} />
          )}
        </>
      )}
    </div>
  )
}
