import classNames from 'classnames'
import GhostButton from 'components/dumb/GhostButton'
import TextInput from 'components/dumb/TextInput'
import { noop } from 'utils'

interface CurrencyInputPanelProps {
  className?: string
  symbol?: string
  value: string
  label?: string | null
  onUserInput?(value: string): void
  leftCount?: string
  leftSub?: string
  rightSub?: string
  rightCount?: string
  onMax?(): void
  currency?: string | null
  disabled?: boolean
  showMaxButton?: boolean
  decimals?: number
  bottomLabelClassName?: string
  bottomRightSub?: string
}

export default function CurrencyInputPanel({
  className = '',
  symbol,
  label = 'You send',
  value,
  leftSub,
  leftCount,
  rightSub,
  rightCount,
  decimals,
  onUserInput = noop,
  onMax = noop,
  disabled = false,
  showMaxButton = false,
  bottomRightSub = '',
  bottomLabelClassName = '',
}: CurrencyInputPanelProps) {
  return (
    <div className={className}>
      <div>
        <div className="w-full grow dxs:w-auto">
          {label && !leftSub && !rightSub ? (
            <div className="mb-2 text-sm font-medium text-codGray">{label}</div>
          ) : null}
          {leftSub || rightSub ? (
            <div className="mb-2 flex items-center justify-between text-sm">
              {label && (
                <span className="font-medium text-codGray">{label}</span>
              )}
              {rightSub && (
                <span className="text-primary">
                  {rightSub}: {rightCount}
                </span>
              )}
            </div>
          ) : null}
          <div
            className={`
              relative flex
              items-center justify-between rounded-[0.3rem]
              bg-white px-3 text-base leading-none
              ${showMaxButton ? 'py-[12px]' : 'py-[14px]'}
            `}
          >
            <TextInput
              value={value}
              placeholder="0.00"
              className={`
                  peer relative z-10 w-full flex-grow bg-transparent text-left  placeholder-grayTertiary focus:outline-none
                  ${disabled ? 'text-boulder' : 'text-codGray'}
                `}
              onChange={onUserInput}
              disabled={disabled}
              decimals={decimals}
            />
            <div
              className={`
                absolute top-0 left-0
                z-0 h-full w-full rounded-[0.3rem] border border-grayDA
                peer-hover:border-gray9A peer-focus:border-gray9A
              `}
            ></div>

            {showMaxButton ? (
              <GhostButton
                disabled={disabled}
                onClick={onMax}
                className="relative z-10 "
              >
                MAX
              </GhostButton>
            ) : null}
            <span className="relative z-10 ml-2 whitespace-nowrap text-base font-light leading-none text-boulder">
              {symbol}
            </span>
          </div>
        </div>
      </div>
      {leftSub && (
        <div
          className={classNames(
            'mt-2 mb-4 flex justify-between',
            bottomLabelClassName
          )}
        >
          <div className="text-sm leading-[140%] text-boulder">
            <p>
              {leftSub}: {leftCount}
            </p>
          </div>
          {bottomRightSub ? (
            <div className="text-sm leading-[140%] text-boulder">
              <p>{bottomRightSub}</p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}
