import { isEmpty } from 'lodash'
import t from 'utils/t'

import { useEffect, useRef, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { ReactComponent as AddIcon } from 'resources/images/add.svg'
import { ReactComponent as CopyIcon } from 'resources/images/copy.svg'
import { ReactComponent as OccamLogoIcon } from 'resources/images/occam-logo.svg'
import { ReactComponent as RazerLogoIcon } from 'resources/images/razer-logo.svg'
import { debounceTime, fromEvent, map, merge, of, switchMap, timer } from 'rxjs'
import { addTokenFx } from 'models/pools'
import ProgressLine, { ProgressLineProps } from './ProgressLine'
import Field, { FieldProps } from './Field'

export interface Props {
  name: string
  logo?: string | null
  subtitle: string
  description: string
  incubated: boolean
  label: string
  tags: string[]
  dueDiligenceReport: string
  website: string
  fields: FieldProps[]
  idoTokenAddress: string
  idoTokenSymbol: string
  idoTokenDecimals: number
  progressLine: ProgressLineProps
  viaAirdrop: boolean

  poolHasBeenDeployed: boolean
  className?: string
}

export default function ProjectPageHeading(props: Props) {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const btnRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (btnRef && btnRef.current) {
      const debounced$ = fromEvent<MouseEvent>(btnRef.current, 'click').pipe(
        debounceTime(150),
        map(() => true)
      )

      const clickCopy$ = debounced$.pipe(
        switchMap(() => merge(of(true), timer(2000).pipe(map(() => false))))
      )

      const clickCopySub = clickCopy$.subscribe(setTooltipOpen)
      return () => {
        clickCopySub.unsubscribe()
      }
    }
  }, [])

  const handleCopyAddressClick = () => {
    navigator.clipboard.writeText(props.idoTokenAddress)
  }

  const handleAddTokenToWalletClick = () => {
    addTokenFx({
      tokenAddress: props.idoTokenAddress,
      tokenSymbol: props.idoTokenSymbol,
      tokenDecimals: props.idoTokenDecimals,
    })
  }

  return (
    <header className={props.className}>
      <div className="relative flex items-start pb-11 ml:pb-0">
        {props.logo && (
          <div
            className="
              h-[4.06rem] w-[4.06rem] shrink-0 rounded-[0.625rem] bg-cover bg-no-repeat
              ml:h-[6.2rem] ml:w-[6.2rem]
              dxs:h-[8.75rem] dxs:w-[8.75rem]
            "
            style={{
              backgroundImage: `url(${props.logo})`,
            }}
          />
        )}

        <div className="top-[0.3rem] ml-5 flex flex-col ml:relative ml:top-0 dxs:top-[1rem]">
          <h1 className="text-[2.5rem] leading-[120%] ml:text-[3.75rem] dxs:text-[5rem]">
            {props.name}
          </h1>
          <div className="absolute bottom-0 left-0 flex ml:static">
            {props.poolHasBeenDeployed && !props.viaAirdrop ? (
              <>
                <button
                  ref={btnRef}
                  data-tooltip-id="copied"
                  data-tooltip-content={t('copied_to_clipboard')}
                  onClick={handleCopyAddressClick}
                  className="inline-flex items-center whitespace-nowrap rounded-[5px] border border-geyser bg-athensGray2 py-[2px] px-2 text-[0.8rem] text-boulder transition-colors hover:border-brightGray mm:text-[0.875rem]"
                >
                  {t('copy_token_address')} <CopyIcon className="ml-[6px]" />
                </button>
                <button
                  className="ml-3 inline-flex items-center whitespace-nowrap rounded-[5px] border border-geyser bg-athensGray2 py-[2px] px-2 text-[0.8rem] text-boulder hover:border-brightGray mm:text-[0.875rem]"
                  onClick={handleAddTokenToWalletClick}
                >
                  {t('add_token_to_wallet')} <AddIcon className="ml-[6px]" />
                </button>
                <Tooltip id="copied" place="bottom" isOpen={tooltipOpen} />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-8 grid gap-[60px] dxs:grid-cols-2">
        <div className="flex flex-col">
          <div className="mb-3 text-2xl text-brightGray">{props.subtitle}</div>
          <p className="mb-3 inline-flex text-boulder">{props.description}</p>
          <div className="mb-11 flex dxs:mb-16">
            {props.tags.map((tag) => (
              <div
                key={`tag-${tag}`}
                className="ml-3 cursor-pointer text-primary first:ml-0 hover:text-primaryHover"
              >
                #{tag}
              </div>
            ))}
          </div>
          <div className="flex w-full flex-col ml:flex-row dxs:mt-auto">
            {props.incubated ? (
              <button className="incubatedByShadow mr-0 inline-flex w-full items-center justify-center rounded-[10px] bg-porcelain pl-2 pr-4 text-codGray transition-colors hover:bg-athensGray2 ml:mr-5 ml:w-auto ">
                <OccamLogoIcon className="mr-1" /> {t('incubated_by')}
              </button>
            ) : null}
            {!isEmpty(props.dueDiligenceReport) && (
              <a
                href={props.dueDiligenceReport}
                target="_blank"
                className="incubatedByShadow ml:ml5 mt-5 ml-0 inline-flex w-full items-center justify-center rounded-[10px] bg-primary pl-2 pr-4 text-white transition-colors hover:bg-primaryHover  ml:mt-0 ml:w-auto"
                rel="noreferrer"
              >
                <RazerLogoIcon className="mr-1" />
                {t('due_diligence_report')}
              </a>
            )}
          </div>
        </div>
        <div className="grid shrink-0 auto-rows-min grid-cols-1 gap-y-5 mm:grid-cols-2 mm:gap-y-8 ml:gap-x-5">
          <ProgressLine {...props.progressLine} />
          {props.fields.map((f) => (
            <Field key={f.title} {...f} />
          ))}
        </div>
      </div>
    </header>
  )
}
