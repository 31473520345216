import StakingPageTiers from 'components/dumb/StakingPageTiers'
import { useStore } from 'effector-react'
import { $staking, fetchStakingInfo } from 'models/staking'
import SmartStakingStats from './SmartStakingStats'
import SmartStakingTerminal from './SmartStakingTerminal'
import { useEffect } from 'react'
import { $address } from 'models/wallet'
import useFetchInterval from 'utils/hooks/useFetchInterval'

export default function SmartStaking() {
  const staking = useStore($staking)
  const address = useStore($address)

  useFetchInterval(fetchStakingInfo)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="relative mb-[5rem]">
      <div className="relative z-20 mb-[60px] dxs:mb-[48px]">
        <div className="dxs:grid-rows-auto grid grid-cols-1 grid-rows-[auto,346px] gap-y-8 gap-x-5 t:grid-rows-[auto,272px] dxs:grid-cols-2 dxs:grid-rows-[auto]">
          <SmartStakingStats />
          <SmartStakingTerminal />
        </div>
      </div>

      {/*<StakingCalculator
        stakingSymbol={staking.stakingSymbol}
        rewardSymbol={staking.rewardSymbol}
        walletBalance={staking.walletBalance}
      />*/}

      <div className="mt-[60px] hidden dxs:mt-[48px] dxs:block">
        <StakingPageTiers
          level={staking.level}
          tiers={staking.tiers}
          stakingSymbol={staking.stakingSymbol}
          hasTier={staking.hasTier}
          address={address}
          leftOffset={0}
          pending={staking.pending}
        />
      </div>
    </div>
  )
}
