import classNames from 'classnames'
import SocialLink, { SocialLinkProps } from 'components/dumb/SocialLink'
import Badge from './Badge'
import { getName, getIcon } from 'utils/blockchain'

export interface HomeUpcomingPoolCardProps {
  background: string
  blockchain: string
  logo: string
  name: string
  subtitle: string
  tags: string[]
  id: string
  links?: SocialLinkProps[]
}

export default function HomeUpcomingPoolCard({
  background,
  blockchain,
  name,
  subtitle,
  links,
  tags,
  id,
  onClick,
}: HomeUpcomingPoolCardProps & {
  onClick: (id: string, blockchain: string) => void
}) {
  return (
    <div className="group relative h-full w-full">
      <div
        className={classNames(
          'blockShadow absolute flex h-full w-full cursor-pointer overflow-hidden rounded-[10px] border border-white bg-athensGray2',
          ' group-hover:blockShadow-cardHover transition-card ds:group-hover:h-[517px]'
        )}
        onClick={() => onClick(id, blockchain)}
      >
        <div className="flex w-full grow flex-col justify-end">
          <div className="relative flex grow flex-col bg-transparent">
            <div
              className={`absolute z-0 h-full w-full bg-cover ${
                background
                  ? 'bg-105 bg-center bg-no-repeat'
                  : 'home-page-noDataGradient'
              }`}
              style={
                background ? { backgroundImage: `url(${background})` } : {}
              }
            ></div>
            <Badge className="absolute right-5 bottom-5 z-10">
              <div className="flex h-full items-center space-x-2">
                <div className="h-4 w-4">{getIcon(blockchain)}</div>
                <span>{getName(blockchain)}</span>
              </div>
            </Badge>
          </div>
          <div className="flex flex-col px-6 py-5">
            <div className="">
              <div className="text-2xl font-light leading-8 text-brightGray">
                {name}
              </div>
              <p className="mt-1 truncate text-base font-light leading-6 text-friarGray">
                {subtitle}
              </p>
              <div className="mt-2 flex flex-wrap space-x-4">
                {tags.map((tag) => (
                  <div
                    key={`tag-${tag}`}
                    className="text-lg leading-none text-primary"
                  >
                    #{tag}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-[22px] flex space-x-3">
              {links?.map((link) => {
                return link.url ? (
                  <div
                    key={`link-${link.icon}`}
                    className="relative h-[30px] w-[30px] rounded-[5px] bg-geyser/40 hover:bg-geyser/80"
                  >
                    <SocialLink
                      url={link.url}
                      icon={link.icon}
                      className="absolute left-1/2 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 text-primary"
                    />
                  </div>
                ) : null
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
