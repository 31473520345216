import { useStore } from 'effector-react'
import { $lmsPools, fetchLMList, setSelectedLMAddress } from 'models/lm'
import { useEffect } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import SmartLiquidityMiningStats from './SmartLiquidityMiningStats'
import SmartLiquidityMiningTerminal from './SmartLiquidityMiningTerminal'
import numeral from 'numeral'
import classNames from 'classnames'
import { ReactComponent as PackFilledIcon } from 'resources/images/icons/pack-filled.svg'
import useFetchInterval from 'utils/hooks/useFetchInterval'

export default function SmartLiquidityMining() {
  const navigate = useNavigate()

  const { address } = useParams()
  const lmsPools = useStore($lmsPools)
  useFetchInterval(fetchLMList)

  const pool = lmsPools.find((p) => p.address === address)
  setSelectedLMAddress(address ?? '')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!pool) {
      const timer = setTimeout(() => {
        navigate('/')
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [pool, navigate])

  if (!pool) return null

  return (
    <div className="relative mt-8 mb-[5rem] dxs:mt-[4rem]">
      <div className="mb-[0.7rem] flex flex-wrap items-baseline">
        <div className="mr-[0.8rem] text-[1.95rem] leading-[120%] text-brightGray">
          {pool.name}
        </div>
        <div className="text-[1.2rem] font-extralight text-brightGray/40">
          {pool.liquidityTokenPrettyName}
        </div>
      </div>
      <div className="mb-[1.8rem] flex flex-wrap gap-[0.75rem]">
        {lmsPools
          .sort((a, b) => {
            if (a.name === 'OccamFi' || b.name === 'OccamFi')
              return a.name === 'OccamFi' ? -1 : 1

            const aApy = numeral(a.apy).value() ?? 0
            const bApy = numeral(b.apy).value() ?? 0
            return aApy > bApy ? -1 : 1
          })
          .map((item) => {
            return (
              <NavLink
                key={item.address}
                to={`/liquidity-mining/${item.address}`}
                className={classNames(
                  `flex h-[1.9rem] items-center rounded-[0.3rem] border
border-geyser/40 px-[0.6rem] text-[0.8rem] text-brightGray
transition-colors hover:bg-white/50`,
                  { 'bg-white/80': item.address === pool.address },
                  { 'border-geyser/0 bg-geyser/40': !item.isOccam },
                  { 'bg-athensGray2/45': item.isOccam }
                )}
              >
                <span className="mr-[0.5rem]">{item.name}</span>
                <span className="text-friarGray">
                  {item.isOccam ? null : <PackFilledIcon className="h-4 w-4" />}
                </span>
              </NavLink>
            )
          })}
      </div>
      <div className="relative z-20 mb-[3rem] dxs:mb-[1.5rem]">
        <div className="grid grid-cols-1 gap-y-8 gap-x-[1.5rem] dxs:grid-cols-2">
          <SmartLiquidityMiningStats pool={pool} />
          <SmartLiquidityMiningTerminal pool={pool} />
        </div>
      </div>
    </div>
  )
}
