import { animated, to, useSpring } from '@react-spring/web'
import classNames from 'classnames'
import TextLink from 'components/dumb/TextLink'
import { openOpenseaLink } from 'models/staking'
import { StakingTierMapped } from 'models/staking/staking'
import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { ReactComponent as CheckIcon } from 'resources/images/icons/check-circle-tier.svg'
import { ReactComponent as LinkIcon } from 'resources/images/icons/external-link.svg'
import { ReactComponent as TierLogo1 } from 'resources/images/tier/logo1.svg'
import { ReactComponent as TierLogo2 } from 'resources/images/tier/logo2.svg'
import { ReactComponent as TierLogo3 } from 'resources/images/tier/logo3.svg'
import { ReactComponent as TierLogo4 } from 'resources/images/tier/logo4.svg'
import { ReactComponent as TierLogo5 } from 'resources/images/tier/logo5.svg'
import { ReactComponent as TierLogo6 } from 'resources/images/tier/logo6.svg'
import { ReactComponent as TierLogo7 } from 'resources/images/tier/logo7.svg'
import { toDecimal } from 'utils/numbers'
import t from 'utils/t'
import { ROMAN_TIER_NAME, getNftUrl } from 'utils/tier'
import StakingTiersSkeleton from './skeleton/StakingTiersSkeleton'

interface TiersProps {
  tiers: StakingTierMapped[]
  level: number
  stakingSymbol: string
  hasTier: boolean
  address: string
  pending: boolean
  leftOffset: number
}
export default function Tiers({
  tiers,
  stakingSymbol,
  hasTier,
  level,
  address,
  leftOffset,
  pending,
}: TiersProps) {
  const [currentTier] = useState(0)
  const nftUrl = getNftUrl(address)

  const aboutTiersLink =
    'https://hackmd.io/@OccamDAO/OccamRazer#Which-are-the-tiers'

  return (
    <div className="relative z-[19] mb-10">
      <div
        className="mb-6 flex justify-between"
        style={{ marginLeft: leftOffset, marginRight: leftOffset }}
      >
        <h2 className="text-2xl font-light leading-[1.3] text-brightGray">
          {t('tiers')}
        </h2>
        <div className="flex items-center space-x-5">
          <TextLink href={aboutTiersLink} className="text-sm">
            {t('about_tiers')}
          </TextLink>
        </div>
      </div>
      {/*<div>{currentTier}</div>*/}
      <div
        className={classNames(
          'no-scrollbar relative flex overflow-x-scroll pb-[3rem]',
          'dxs:overflow-x-visible',
          'w-screen dxs:w-auto'
        )}
      >
        {pending ? (
          <div className="hidden h-[368px] w-full dxs:block">
            <StakingTiersSkeleton />
          </div>
        ) : (
          false
        )}
        {!pending ? (
          <div
            className="mt-4 flex items-center dxs:mt-0"
            style={{ paddingLeft: leftOffset, paddingRight: leftOffset }}
          >
            {tiers.map((tier) => (
              <TierCard
                translucent={
                  hasTier &&
                  tier.level > level &&
                  currentTier < tier.level &&
                  currentTier !== 6
                }
                collapsed={tier.level > currentTier}
                key={tier.level}
                level={tier.level}
                name={tier.name}
                votingPower={tier.votingPower}
                userTier={level}
                poolWeightMultiplier={tier.allocationMultiple}
                participationTickets={tier.participationTickets}
                minStake={tier.minStake}
                stakingSymbol={stakingSymbol}
                nftUrl={nftUrl}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

function TierCard({
  level,
  userTier,
  name,
  translucent,
  poolWeightMultiplier,
  participationTickets,
  votingPower,
  minStake,
  stakingSymbol,
  nftUrl,
  collapsed,
}: {
  level: number
  userTier: number | null
  poolWeightMultiplier: string
  name: string
  participationTickets: string
  minStake: string
  votingPower: string
  stakingSymbol: string
  collapsed: boolean
  nftUrl?: string
  translucent?: boolean
}) {
  const oddsMultiple = toDecimal(participationTickets).eq(0)
    ? 'Guaranteed'
    : `+${participationTickets}`

  const { logo, monk } = React.useMemo(() => {
    const elProps = {
      className: 'absolute z-[-1] right-0 -top-1',
    }
    switch (level) {
      case 1:
        return { logo: <TierLogo1 {...elProps} />, monk: 'bg-monk1' }
      case 2:
        return { logo: <TierLogo2 {...elProps} />, monk: 'bg-monk2' }
      case 3:
        return { logo: <TierLogo3 {...elProps} />, monk: 'bg-monk3' }
      case 4:
        return { logo: <TierLogo4 {...elProps} />, monk: 'bg-monk4' }
      case 5:
        return { logo: <TierLogo5 {...elProps} />, monk: 'bg-monk5' }
      case 6:
        return { logo: <TierLogo6 {...elProps} />, monk: 'bg-monk6' }
      case 7:
        return { logo: <TierLogo7 {...elProps} />, monk: 'bg-monk7' }
      default:
        return { logo: null, monk: '' }
    }
  }, [level])
  const isOlympus = level === 1
  const isNemea = level === 2

  const tierBadgeText = React.useMemo(() => {
    if (isOlympus) {
      return 'ACCESS TO PRE-IDO ROUNDS'
    }

    if (isNemea) {
      return 'DAO GUILD CREATION'
    }

    return ''
  }, [isOlympus, isNemea])

  const isUserTier = level === userTier

  const handleClick = () => {
    if (isUserTier) {
      openOpenseaLink()
    }
  }

  const c = isUserTier ? { backgroundImage: `url(${nftUrl})` } : {}

  const [{ zoom, scale }, api] = useSpring(() => ({
    scale: 1,
    zoom: 0,
    x: 0,
    y: 0,
    config: { mass: 5, tension: 650, friction: 40 },
  }))

  const handleMouseLeaveCard = () => {
    api({ scale: 1 })
  }

  const handleMouseEnterCard = () => {
    api({ scale: 1.04 })
  }
  const scaleTo = to([scale, zoom], (s, z) => s + z)

  return (
    <animated.div
      onMouseEnter={handleMouseEnterCard}
      onMouseLeave={handleMouseLeaveCard}
      className={classNames(
        'group relative h-[368px] transition-spacing hover:z-40',
        {
          'ml-[30px] dxs:-ml-[84px] dm:-ml-[84.5px] dl:-ml-[47px]':
            collapsed && level < 7,
          'ml-[30px]': !collapsed,
          'w-[208px]': !isOlympus,
          'w-[240px]': isOlympus,
        }
      )}
      style={{
        transform: 'perspective(600px)',
        scale: scaleTo,
      }}
    >
      <div className="absolute h-full w-full bg-athensGray" />
      <div
        className={classNames(
          'blockShadow relative flex h-full w-full rounded-block group-hover:opacity-100',
          {
            'opacity-60': translucent,
            'z-20': !translucent,
          }
        )}
      >
        <div
          className={classNames('absolute z-10 h-[100%] w-[100%]', {
            'block-gradient': !isOlympus && !isNemea && !isUserTier,
            'user-tier-gradient': isUserTier,
          })}
        />
        <div
          className={classNames(
            'absolute z-10 h-[100%] w-[100%] rounded-[10px]',
            {
              'tier-olympus-gradient': isOlympus && !isUserTier,
              'tier-nemea-gradient': isNemea && !isUserTier,
              'high-tier-user-gradient': (isNemea || isOlympus) && isUserTier,
            }
          )}
        />
        <div
          className={classNames(
            'absolute z-10 h-[100%] w-[100%] rounded-[0.5rem] bg-center',
            {
              'bg-bgOlympus': isOlympus,
              'bg-bgNemea': isNemea,
            }
          )}
        />
        <div className="relative z-30 w-full px-4 py-3">
          <div className="relative">
            <div className="text-2xl font-medium leading-none text-brightGray">
              {name}
            </div>
            {logo}
            <div
              className={classNames(
                'mt-[0.3rem] mb-2 flex items-center space-x-1 leading-none',
                {
                  'text-hippieBlue/30': !isUserTier,
                  'text-malachite': isUserTier,
                }
              )}
            >
              <span className="text-[0.93rem]">{minStake}</span>
              <span className="text-[0.75rem]">{stakingSymbol}</span>
              {isUserTier && <CheckIcon className="h-4 w-4" />}
            </div>
            <Tooltip
              id="nft-tooltip"
              place="right"
              render={() => (
                <div className="flex items-center space-x-1">
                  <span>Watch NFT on</span>
                  <span className="font-medium text-primary">Opensea</span>
                  <LinkIcon />
                </div>
              )}
            ></Tooltip>
            <div
              data-tooltip-id={isUserTier ? 'nft-tooltip' : ''}
              className={classNames(
                'relative h-[11rem] w-full overflow-hidden rounded-[0.28rem] border border-white',
                'group-hover:grayscale-0',
                {
                  'grayscale-[70%] ': translucent,
                  'cursor-pointer': isUserTier,
                }
              )}
              onClick={handleClick}
            >
              <div
                className={classNames(
                  'absolute h-full w-full bg-cover bg-center bg-no-repeat',
                  isUserTier ? '' : monk
                )}
                style={{
                  ...c,
                }}
              />
              <span className="absolute top-2 right-3 text-[0.75rem] font-light text-hippieBlue/54">
                {ROMAN_TIER_NAME[level]}
              </span>
            </div>
            <div className="mt-4 flex flex-col space-y-2">
              <div className="flex space-x-1 whitespace-nowrap text-xs leading-3">
                <div className="text-brightGray">Allocation relative</div>
                <div className="relative grow">
                  <div className="tier-dots" />
                </div>
                <div className="items-end whitespace-nowrap text-xs font-medium text-primary">
                  {poolWeightMultiplier}
                </div>
              </div>
              <div className="flex space-x-1 whitespace-nowrap text-xs leading-3">
                <div className="text-brightGray">Participation</div>
                <div className="relative grow">
                  <div className="tier-dots" />
                </div>
                <div className="items-end whitespace-nowrap text-xs font-medium text-primary">
                  {oddsMultiple}
                </div>
              </div>
              <div className="flex space-x-1 whitespace-nowrap text-xs leading-3">
                <div className="text-brightGray">Base voting power</div>
                <div className="relative grow">
                  <div className="tier-dots" />
                </div>
                <div className="items-end whitespace-nowrap text-xs font-medium text-primary">
                  {votingPower}
                </div>
              </div>
            </div>
          </div>
          {isNemea || isOlympus ? (
            <span className="absolute bottom-4 left-4 rounded-[16px] bg-primary py-[2px] px-[6px] text-[8px] uppercase text-white">
              {tierBadgeText}
            </span>
          ) : null}
        </div>
      </div>
    </animated.div>
  )
}
