import { SecondaryMarket } from 'gql'
import numeral from 'numeral'
import React from 'react'
import { openUrl } from 'utils'
import arc from 'utils/arc'
import { toDecimal } from 'utils/numbers'
import t from 'utils/t'
import Block from './Block'
import DotLeaders from './DotLeaders'
import Dropdown from './Dropdown'

export interface Props {
  totalRaised: string
  tokensToVest: string
  tokensSoldPercent: string
  athTokenPrice: string
  athRoi: string
  secondaryMarkets: SecondaryMarket[]
  symbol: string
  currentPeriodIndex: number
  unlockPercentages: string[]
  vested: string
  tokensHasBeenDistributed: boolean
  hide?: boolean
}

export default function SaleStatistics({
  totalRaised,
  tokensToVest,
  athTokenPrice,
  athRoi,
  secondaryMarkets,
  symbol,
  currentPeriodIndex,
  tokensSoldPercent,
  unlockPercentages,
  vested,
  tokensHasBeenDistributed,
  hide = false,
}: Props) {
  const paths = React.useMemo(
    () =>
      unlockPercentages.reduce<Arc[]>((arr, ch, index) => {
        const prevEnd = arr[arr.length - 1] ? arr[arr.length - 1].end : 0
        const prevPercent = arr[arr.length - 1]
          ? arr[arr.length - 1].percent
          : 0

        const percent = toDecimal(prevPercent).plus(ch)

        const end = toDecimal(360).mul(toDecimal(percent)).div(100).toNumber()

        return arr.concat({
          d: arc({
            x: 150,
            y: 150,
            R: 150,
            r: 137,
            start: prevEnd + 2,
            end: end - 2,
          }),
          end,
          percent: percent.toNumber(),
          color: index > currentPeriodIndex ? 'text-quillGray' : 'text-primary',
        })
      }, []),
    [unlockPercentages, currentPeriodIndex]
  )

  if (hide) return null

  const markets = secondaryMarkets.map((m) => ({
    text: m.name,
    onClick: () => openUrl(m.url),
  }))

  return (
    <div className="col-span-2 grid h-full grid-cols-1 gap-y-5 gap-x-5 ml:col-span-1 ml:grid-cols-2">
      <Block containerClassName="flex flex-col h-full">
        <div className="flex h-full flex-col justify-between">
          <div className="">
            <div className="text-lg text-codGray">{t('sale_has_ended')}</div>
            <div className="mb-4 text-sm text-boulder">
              {t('successfully_funded')}
            </div>
            <div className="mb-[1rem]">
              <DotLeaders name={t('total_raised')} value={totalRaised} />
              <DotLeaders
                name={t('tokens_sold')}
                value={`${numeral(tokensSoldPercent).format('0,00')}%`}
              />
              <DotLeaders name={t('ath_token_price')} value={athTokenPrice} />
              <DotLeaders name={t('ath_roi')} value={athRoi} />
            </div>
          </div>
          {secondaryMarkets.length > 0 && (
            <Dropdown items={markets}>
              {t('trade')} <span className="font-bold">{symbol}</span>
            </Dropdown>
          )}
        </div>
      </Block>
      <Block containerClassName="flex flex-col h-full">
        <div className="mt-4 flex h-full flex-col justify-between">
          <div className="relative">
            <svg className="h-full w-full" viewBox="0 0 300 300">
              {paths.map(({ d, color }) => (
                <path d={d} className={`fill-current ${color}`} />
              ))}
            </svg>
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="mb-1 text-base text-codGray">{vested}</div>
              <div className="text-[10px] text-friarGray">
                /{tokensToVest} {symbol}
              </div>
            </div>
          </div>
          <div className="m-auto mt-3 text-xl font-light text-codGray ml:text-2xl">
            {tokensHasBeenDistributed
              ? t('vesting_ended')
              : t('vesting_progress')}
          </div>
        </div>
      </Block>
    </div>
  )
}

type Arc = {
  d: string
  color: string
  end: number
  percent: number
}
