import get from 'lodash/get'
import strings from './strings'
import { cloneElement } from 'react'

type ValuesObject = {
  [k: string]: any
}

const t = (k: string, value?: ValuesObject) => {
  const text = get(strings(), k, k)

  if (!value) {
    return text
  }

  const regex = /(\{\{.*?\}\})|(<.*?\/>)/

  return text.split(regex).map((el: string, index: number) => {
    const isKey = (s: string) => /\{+[^}]+\}/.test(s)
    const isBr = (s: string) => /br\s*/.test(s)

    if (isKey(el)) {
      const pure = el.replace(/\{|\}/g, '')
      return (
        // <span className="inline" key={'el-' + index}>
        // {
        cloneElement(<span>{get(value, pure, el)}</span>, {
          key: index,
        })

        // }
        // </span>
      )
    }

    if (isBr(el)) {
      return <br key={'br-' + index} />
    }

    return el
  })
}

export default t
